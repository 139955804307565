import * as React from "react";
import { Link, HeadFC } from "gatsby";
import serverErrorInternal from "../images/page500.png";



const ServerErrorInternalPage = () => {
  return (
    <main className="flex flex-col items-center  justify-center  h-[100vh] bg-banner-404  bg-cover ">
      <img src={serverErrorInternal} alt="page not found" className="w-7/12 max-w-xs sm:mt-10"/>
      <p className="ff-cg--bold text-8xl mt-3 lg:mt-0  md:text-[150px]">500</p>
      <p className="ff-cg--semibold text-xl md:text-3xl  text-[#894216]">Uh oh, there seems to be a problem.</p>
      <p className=" text-[18px] lg:text-[24px] font-light ff-cg--medium mt-2 text-center">
      We’re unable to fulfill you request but we are looking into the issue.
        {/* <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br /> */}
      </p>
      <p className="text-[18px] lg:text-[24px] font-light ff-cg--semibold text-center">
      Please refresh your browser and if the error continues, please <Link to="/contact" className="text-red-500 underline">contact us</Link>
      </p>
     
    </main>
  );
};

export default ServerErrorInternalPage;

export { Head } from "../components/Layout/Head";

