import * as React from "react";
import { HeadFC } from "gatsby";

export const Head = (props: any) => {
  const { title } = props;
  return (
    <>
      <meta name="robots" content="noindex" />
      <title>{title ?? "Workforce Forward"}</title>
    </>
  );
};
